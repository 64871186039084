import { ConfigProvider, DatePicker, Spin, Statistic } from "antd";
import "./styles/website-stats.css";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

const WebsiteStats: React.FC<{}> = (props) => {
  const [minMaxDates, setMinMaxDates] = React.useState<{
    minDate: Dayjs;
    maxDate: Dayjs;
  } | null>(null);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [soldItems, setSoldItems] = React.useState<
    | {
        id: string;
        buyPrice: string;
        sellPrice: string;
        sellDate: Date;
        name: string;
      }[]
    | null
  >(null);

  React.useEffect(() => {
    fetch("https://api.ybs-closet.com/inventory/soldItems", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setMinMaxDates({
          minDate: dayjs(data.minDate),
          maxDate: dayjs(data.maxDate),
        });
        setStartDate(dayjs(data.minDate));
        setEndDate(dayjs(data.maxDate));
      });
  }, []);

  React.useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    fetch("https://api.ybs-closet.com/inventory/soldItems", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSoldItems(data);
      });
  }, [startDate, endDate]);

  return (
    <div>
      <h1>Sales Insights</h1>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ffffff",
            colorBgBase: "#1e1e1e",
            colorTextBase: "#ffffff",
            colorText: "#ffffff",
            colorTextPlaceholder: "#1e1e1e",
            colorTextHeading: "#ffffff",
            colorBorder: "#4b4b4b",
            colorBorderSecondary: "#4b4b4b",
            colorBgBlur: "#1e1e1e",
            colorBorderBg: "#1e1e1e",
            controlOutline: "rgba(125, 104, 0, 0.27)",
            colorTextQuaternary: "#ffffff",
            controlItemBgHover: "#4b4b4b",
            colorBgSpotlight: "#4b4b4b",
            fontFamily: `"Noto Sans", sans-serif`,
          },
        }}
      >
        <div className="filter">
          <div className="filter-title">
            Choose a date range to view statistics
          </div>
          <DatePicker.RangePicker
            title="Date Range"
            minDate={minMaxDates?.minDate}
            maxDate={minMaxDates?.maxDate}
            value={[startDate, endDate]}
            onChange={(dates) => {
              if (dates && dates.length === 2) {
                setStartDate(dates[0] ? dates[0] : null);
                setEndDate(dates[1] ? dates[1] : null);
              }
            }}
          />
        </div>
        <div className="statistics">
          {soldItems === null ? (
            <Spin
              size="large"
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          ) : (
            <div className="statistics">
              <Statistic
                title="Total Revenue"
                value={
                  soldItems
                    .reduce((acc, item) => {
                      return acc + parseFloat(item.sellPrice);
                    }, 0)
                    .toFixed(2) + " USD"
                }
              />
              <Statistic title="Total Items Sold" value={soldItems.length} />
              <Statistic
                title="Average Selling Price"
                value={
                  (
                    soldItems.reduce((acc, item) => {
                      return acc + parseFloat(item.sellPrice);
                    }, 0) / soldItems.length
                  ).toFixed(2) + " USD"
                }
              />
              <Statistic
                title="Total Profit"
                value={
                  soldItems
                    .filter((item) => {
                      return item.buyPrice && item.buyPrice !== "0";
                    })
                    .reduce((acc, item) => {
                      return (
                        acc +
                        (parseFloat(item.sellPrice) - parseFloat(item.buyPrice))
                      );
                    }, 0)
                    .toFixed(2) + " USD"
                }
              />
              <Statistic
                title="Average Profit Margin"
                value={
                  (
                    soldItems
                      .filter((item) => {
                        return item.buyPrice && item.buyPrice !== "0";
                      })
                      .reduce((acc, item) => {
                        return (
                          acc +
                          (parseFloat(item.sellPrice) -
                            parseFloat(item.buyPrice))
                        );
                      }, 0) / soldItems.length
                  ).toFixed(2) + " USD"
                }
              />
            </div>
          )}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default WebsiteStats;
